import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../../hooks/useHomepage'
import Bag from '../../../images/bag.png'
import Bank from '../../../images/bank.png'
import Salad from '../../../images/salad.png'
import ScrollAnimation from '../../scroll-animation/Container'
import PartnerDialog from '../partner-dialog/dialog'
import './why-restaurants-love-us.less'

const WhyRestaurantsLoveUsContainer = () => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const { animationProps } = useHomepage()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <ScrollAnimation {...animationProps}>
      <div className="why-restaurants-love">
        <div className="why-restaurants-love__title">
          <div className="why-restaurants-love__title__text">{t('Why Restaurants Love MealPal')}</div>
          <div className="tile">
            <div className="tile__image">
              <img src={Salad} className="tile__image__img" />
            </div>
            <div className="tile__title tile__title__one">{t('Increase Operational Efficiency')}</div>
            <div className="tile__subtitle tile__subtitle__one">
              {t(
                'MealPal features one menu item per day (with no customization!),\
                      allowing your team to prepare orders 3x as fast.',
              )}
            </div>
          </div>
          <div className="tile">
            <div className="tile__image">
              <img src={Bank} className="tile__image__img" />
            </div>
            <div className="tile__title">{t('Earn Profitable Revenue')}</div>
            <div className="tile__subtitle">
              {t(
                'MealPal reduces your labor costs to help you produce your most\
                      profitable orders.',
              )}
            </div>
          </div>
          <div className="tile">
            <div className="tile__image">
              <img src={Bag} className="tile__image__img" />
            </div>
            <div className="tile__title">{t('Prepare Pickup Orders')}</div>
            <div className="tile__subtitle tile__subtitle__three">
              {t('All MealPal orders are picked up in-person by the customer each day. There are no delivery drivers!')}
            </div>
          </div>
        </div>
        <div className="why-restaurants-love__button">
          <button className="red-cta" onClick={handleOpen}>
            {t('Learn More')}
          </button>
        </div>
        <PartnerDialog open={open} onClose={handleClose} />
      </div>
    </ScrollAnimation>
  )
}

export default WhyRestaurantsLoveUsContainer
