import Dialog from '@mui/material/Dialog'
import React, { useState } from 'react'

import closeImg from '../../../../src/images/close.svg'
import PartnerForm from '../form/Form'
import './dialog.less'

export interface PartnerDialogProps {
  open: boolean
  onClose: () => void
}

type State = {
  closeClicked: boolean
}

function PartnerDialog(props: PartnerDialogProps) {
  const { onClose, open } = props
  const [state, setState] = useState<State>({ closeClicked: true })

  const handleClose = () => {
    if (state.closeClicked) {
      onClose()
    }
  }

  const onClickHandler = () => {
    const closeClicked = true
    setState({ ...state, closeClicked })
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="partner-dialog__close">
        <img src={closeImg} onClick={onClickHandler} />
      </div>
      <PartnerForm />
    </Dialog>
  )
}

export default PartnerDialog
