import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../../hooks/useHomepage'
import PartnerCompaniesLg from '../../../images/partner-companies-lg.png'
import PartnerCompaniesSm from '../../../images/partner-companies-sm.png'
import PartnerCompaniesXs from '../../../images/partner-companies-xs.png'
import ScrollAnimation from '../../scroll-animation/Container'
import PartnerDialog from '../partner-dialog/dialog'
import './Companies.less'

const CompaniesContainer = () => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const { animationProps } = useHomepage()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="companies">
      <ScrollAnimation {...animationProps}>
        <div className="companies__title">{t('MealPal Partners with the Best Restaurants')}</div>
      </ScrollAnimation>
      <ScrollAnimation {...animationProps}>
        <div className="companies__logo">
          <img className="companies__logo--lg" src={PartnerCompaniesLg} />
        </div>
        <div className="companies__logo companies__logo--sm">
          <img src={PartnerCompaniesSm} />
        </div>
        <div className="companies__logo companies__logo--xs">
          <img src={PartnerCompaniesXs} />
        </div>
      </ScrollAnimation>
      <ScrollAnimation {...animationProps}>
        <div className="companies__button">
          <button className="red-cta" onClick={handleOpen}>
            {t('Learn More')}
          </button>
        </div>
        <PartnerDialog open={open} onClose={handleClose} />
      </ScrollAnimation>
    </div>
  )
}

export default CompaniesContainer
