import React, { useLayoutEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import SEO from '../seo'
import CompaniesContainer from './companies/Companies'
import Hero from './hero/Hero'
import NayaTestimonialContainer from './naya-testimonial/Naya'
import WhyRestaurantsLoveUsContainer from './why-restaurants-love-us/WhyRestaurantsLoveUs'

type Props = RouteProps<'/partner'>

const PartnerContainer: React.FC<Props> = () => {
  useLayoutEffect(() => {
    window.scrollTo(4, 4)
  }, [])

  return (
    <>
      <SEO title="Partner" />
      <Hero />
      <WhyRestaurantsLoveUsContainer />
      <NayaTestimonialContainer />
      <CompaniesContainer />
    </>
  )
}

export default PartnerContainer
