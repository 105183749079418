import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import ReactPlayer from 'react-player'

import NayaVideo from '../../../../static/video/naya_landing.mp4'
import useHomepage from '../../../hooks/useHomepage'
import ScrollAnimation from '../../scroll-animation/Container'
import Form from '../form/Form'
import './hero.less'

const HeroContainer = () => {
  const { t } = useTranslation()
  const { animationProps } = useHomepage()

  return (
    <ScrollAnimation {...animationProps}>
      <div className="partner-hero">
        <div className="partner-hero__group">
          <div className="video-wrapper">
            <ReactPlayer
              url={[{ src: NayaVideo }]}
              className="partner-hero__player"
              width="100%"
              height="100%"
              playing
              loop
              muted
            />
          </div>
          <div className="partner-hero__formfield">
            <div className="partner-hero__text">{t('Grow your Business with MealPal')}</div>
            <Form />
          </div>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default HeroContainer
