import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import useHomepage from '../../../hooks/useHomepage'
import NayaOwnerCropped from '../../../images/hady-cropped.png'
import NayaOwnerMobile from '../../../images/naya-owner.jpg'
import ScrollAnimation from '../../scroll-animation/Container'
import PartnerDialog from '../partner-dialog/dialog'
import './Naya.less'

const NayaTestimonialContainer = () => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const { animationProps } = useHomepage()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="naya">
      <ScrollAnimation {...animationProps}>
        <div className="naya__gray-banner">
          <div className="naya__testimonial">
            <div className="naya__testimonial__text">
              {t(
                '"MealPal is a great partner. We have a line out the door almost \
              every day during lunch. MealPal has helped us increase our operational \
              capacity by allowing us to prepare orders in a more efficient way."',
              )}
              <span className="naya__testimonial__subtitle">
                <em>{t(' - Hady, Founder at NAYA')}</em>
              </span>
            </div>
            <div className="naya__naya-hady-mobile">
              <img src={NayaOwnerMobile} className="naya__naya-hady-mobile"></img>
            </div>
            <div className="naya__button-center">
              <button className="red-cta red-cta-naya" onClick={handleOpen}>
                {t('Learn More')}
              </button>
            </div>
            <PartnerDialog open={open} onClose={handleClose} />
          </div>
          <img src={NayaOwnerCropped} className="naya__naya-hady-cropped"></img>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default NayaTestimonialContainer
