import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

import RedCheck from '../../../../src/images/red_check.svg'
import { LenientValidatePhoneNumber, ValidateEmail, ValidateUSPhoneNumber } from '../../../helpers'
import useHomepage from '../../../hooks/useHomepage'
import CountryRequirement from '../../../models/CountryRequirement'
import { ApiService } from '../../../services'
import InlineSpinner from '../../common/InlineSpinner'
import './form.less'

type State = {
  restaurant_name: string
  zip: string | undefined
  post_code: string | undefined
  first_name: string
  last_name: string
  phone_number: string
  email: string
  loading: boolean
  utm_content: string
  isValidEmail: boolean
  isValidRestaurantName: boolean
  isValidPhone: boolean
  isValidForm: boolean
}

const Form = () => {
  const { partnerFormSubmitted, setPartnerFormSubmitted, countryCodeIso3 } = useHomepage()
  const { t } = useTranslation()

  const country = new CountryRequirement({ countryCodeIso3 })
  const isZipRequired = country.isZipRequired()

  const [state, setState] = useState<State>({
    restaurant_name: '',
    zip: '',
    post_code: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    loading: false,
    utm_content: '',
    isValidEmail: true,
    isValidRestaurantName: true,
    isValidPhone: true,
    isValidForm: true,
  })

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return

    const parsedPhoneNumber = parsePhoneNumber(state.phone_number)
    const isValidEmail = ValidateEmail(state.email)
    const isValidRestaurantName = state.restaurant_name !== ''
    let dialCode = ''
    let isValidPhone = false

    if (parsedPhoneNumber) {
      dialCode = parsedPhoneNumber.countryCallingCode.toString()

      isValidPhone =
        dialCode === '1'
          ? ValidateUSPhoneNumber(state.phone_number)
          : LenientValidatePhoneNumber(state.phone_number, dialCode)

      setState({ ...state, isValidPhone })
    }

    const isValidForm = isValidEmail && isValidRestaurantName && isValidPhone ? true : false
    setState({ ...state, isValidEmail, isValidRestaurantName, isValidPhone, isValidForm })

    if (isValidEmail && isValidRestaurantName && isValidPhone && isValidForm) {
      ApiService.createRestaurantLead({
        phone_number: state.phone_number.slice(dialCode.length + 1),
        first_name: state.first_name,
        last_name: state.last_name,
        restaurant_name: state.restaurant_name,
        email: state.email,
        zip: state.zip,
        post_code: state.post_code,
        country_code: dialCode,
        utm_content: state.utm_content,
      }).finally(() => {
        setPartnerFormSubmitted(true)
      })
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.value
    setState({ ...state, [target.name]: value })
  }

  const onPhoneChangeHandler = (v: string) => {
    const phone = v
    setState({ ...state, ['phone_number']: phone })
  }

  const zipElement = () => {
    const defaultProps = {
      className: 'partner-form__input',
      type: 'text',
      onChange: onChangeHandler,
    }

    if (isZipRequired) {
      return <input {...defaultProps} name="zip" value={state.zip} placeholder={t('Zip Code')} />
    } else {
      return <input {...defaultProps} name="post_code" value={state.post_code} placeholder={t('Post Code')} />
    }
  }

  const phoneNumberElement = () => {
    const inputProps = {
      className: `partner-form__input partner-form__input--phone ${
        !state.isValidPhone && 'partner-form__input__error'
      }`,
      name: 'phone_number',
      required: true,
    }

    const flag_class = !state.isValidPhone ? 'flag-dropdown-partner__error' : ''

    return (
      <PhoneInput
        countrySelectProps={{ className: flag_class }}
        containerStyle={{ className: 'form-control' }}
        inputProps={inputProps}
        inputClass={'partner-form__input'}
        defaultCountry="US"
        value={state.phone_number}
        disableCountryGuess={true}
        onChange={onPhoneChangeHandler}
        placeholder={t('Phone Number')}
        className={`partner-form__input ${flag_class}`}
      />
    )
  }

  return (
    <form className="partner-form" onSubmit={onSubmitHandler}>
      {!partnerFormSubmitted && (
        <div className="partner-form__field">
          <div className={`partner-form__field__text ${!state.isValidForm && 'partner-form__field__text__error'}`}>
            {t('Get Started')}
          </div>
          <div className={state.isValidForm ? 'partner-form__non-error' : 'partner-form__error'}>
            {t('*All fields are required')}
          </div>
          <input
            className={`partner-form__input ${!state.isValidRestaurantName && 'partner-form__input__error'}`}
            type="text"
            name="restaurant_name"
            value={state.restaurant_name}
            placeholder={t('Restaurant Name')}
            onChange={onChangeHandler}
          />
          {zipElement()}
          <div className="partner-form__group">
            <input
              className="partner-form__input partner-form__input--names"
              type="text"
              name="first_name"
              value={state.first_name}
              placeholder={t('First Name')}
              onChange={onChangeHandler}
            />
            <input
              className="partner-form__input partner-form__input--names"
              type="text"
              name="last_name"
              value={state.last_name}
              placeholder={t('Last Name')}
              onChange={onChangeHandler}
            />
          </div>
          {phoneNumberElement()}
          <input
            className={`partner-form__input ${!state.isValidEmail && 'partner-form__input__error'}`}
            type="text"
            name="email"
            value={state.email}
            placeholder={t('Your email address')}
            onChange={onChangeHandler}
          />
          <button className="partner-form__input red-cta" type="submit">
            {state.loading ? <InlineSpinner /> : '' || t('Learn More')}
          </button>
        </div>
      )}
      {partnerFormSubmitted && (
        <div className="partner-form__field-circle">
          <img src={RedCheck}></img>
          <div className={`partner-form__field-circle__thanks-text`}>{t('Thanks for contacting us!')}</div>
          <div className={`partner-form__field-circle__in-touch-text`}>{t("We'll be in touch with you shortly.")}</div>
        </div>
      )}
    </form>
  )
}

export default Form
